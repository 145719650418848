import type { PPSETTeaserVerticalChipsV1Attributes } from '@/features/vertical-widget/ppset-teaser/vertical-chips/ppset-teaser-vertical-chips-v1-type';

import React from 'react';

import { bemModule } from '@/core/utils/bem-classname';

import styles from './ppset-teaser-vertical-chips-v1.module.scss';

const bem = bemModule(styles);

export default function PPSETTeaserVerticalChipsV1({ chips }: PPSETTeaserVerticalChipsV1Attributes) {
    return (
        <div className={'flex gap-horizontal gap-8 margin-bottom-10 text-white'}>
            {chips.map(({ linkUrl, title }, index) => {
                return (
                    <a
                        className={`border pointer ${bem(styles.chip, { active: linkUrl === null })}`}
                        href={linkUrl ?? undefined}
                        key={`${linkUrl}-${index}`}
                    >
                        {title}
                    </a>
                );
            })}
        </div>
    );
}
